import React, { useEffect } from "react";
import { Grid, GridColumn as Column, GridColumnMenuProps, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { LibrariesListModel } from "../models/librariesModel";
import { CompositeFilterDescriptor, filterBy, SortDescriptor } from "@progress/kendo-data-query";
import { GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { Input } from "@progress/kendo-react-inputs";
import { Icon, SvgIcon } from '@progress/kendo-react-common';
import { Button } from "@progress/kendo-react-buttons";
import { useSearchTextContext } from "../context/SearchTextContext";
import { useMessageBoxContext } from '../context/MessageBoxContext';  
import { docmultiSearchApi } from "../apis/docmultiSearch";
import { Loader } from "@progress/kendo-react-indicators";
import { useControlContext } from "../context/ControlContext";
import { filterIcon } from "@progress/kendo-svg-icons";
import { sortDescSmallIcon, sortAscSmallIcon } from '@progress/kendo-svg-icons';
import { useLibrariesContext } from "../context/LibrariesContext";
import { useLibraryContext } from "../context/LibraryContext";
import { isMobile } from 'react-device-detect';

interface DocMultiProps {
  library: string;
}

interface LibrariesProps {
  data: LibrariesListModel[];
  filter: CompositeFilterDescriptor;
}

export const DocMulti: React.FC<DocMultiProps>= ( { library }) => {    
  const baseSort = [{ field: 'description', dir : 'asc'}];
  const librariesDefault : LibrariesProps = { data: [], filter: { logic: 'and', filters: [] } };
  const { Libraries, setLibraries } = useLibrariesContext();
  const [ librariesGrid, setLibrariesGrid ] = React.useState<LibrariesProps>(librariesDefault);
  const [ sort, setSort ] = React.useState<SortDescriptor[]>([]);
  const { Library, setLibrary } = useLibraryContext();
  const [ Loading, setLoading ] = React.useState<boolean>(false);
  const [ filterInput, setFilterInput ] = React.useState('');
  const { setCommandControlContext } = useControlContext();
  const { SearchText } = useSearchTextContext();   
  const [ descOnOff, setDescOnOff ] = React.useState<boolean>(false);
  const { setMessageBox } = useMessageBoxContext();

  // Hook para atualizar a lista de acervos
  useEffect(() => { 
    if (Library === null || Library?.listLibraries === null || Library?.listLibraries === undefined) return;

    var librariesTmp = Library?.listLibraries ?? [];
    for (let i = 0; i < librariesTmp.length; i++) {
        librariesTmp[i].contador = i + 1;
        librariesTmp[i].ocorrencias = 0;
    }

    setLibraries(librariesTmp);
    setLibrariesGrid({ data: librariesTmp, filter: { logic: 'and', filters: [] } } );
  }, [Library]);

  // Hook para fazer a pesquisa
  useEffect(() => {
    const search = async () : Promise<boolean> => {
      try {
        if (Libraries === undefined || Libraries === null) return false;
        if (SearchText === undefined || SearchText === null || SearchText?.trim() === '') {
          for (let i = 0; i < librariesGrid.data.length; i++) {
            librariesGrid.data[i].ocorrencias = 0;
          }

          setLibrariesGrid({ data: librariesGrid.data, filter: librariesGrid.filter } as LibrariesProps);
          window.history.replaceState(null, "", "/" + library);
          return false;
        }

        setLoading(true);

        var items = Libraries;
        await docmultiSearchApi(items, SearchText!).then((updatedItems) => {
          const sortedItems = sortItemsByField(updatedItems, 'ocorrencias', 'desc');

          let DocMultiMatchsTotal = 0;
          updatedItems.forEach(item => {
              DocMultiMatchsTotal += item.ocorrencias ?? 0;
          });

          setCommandControlContext({ DocMultiMatchsTotal: DocMultiMatchsTotal });
          setLibrariesGrid({ data: sortedItems, filter: librariesGrid.filter } as LibrariesProps);
          window.history.replaceState(null, "", "/" + library + "/search/" + SearchText);
        });

        setLoading(false);
      }
      catch (error) {
        setMessageBox("Erro na pesquisa");
      }
  
      return true;
    }
  
    search();
  }, [SearchText]);
  
  const sortItemsByField = (items: any[], fieldName: string, direction: string) => {
      return items.sort((a, b) => {
          if (direction === 'asc') {
              return (a[fieldName] > b[fieldName]) ? 1 : ((b[fieldName] > a[fieldName]) ? -1 : 0);
          } else {
              return (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0);
          }
      });
  };

  const librariesSorted = React.useMemo(() => {
    const filteredData = librariesGrid.data.filter(item =>
      item.description.toLowerCase().includes(filterInput.toLowerCase())
    );

    var result = filteredData.slice().sort((a, b) => {
      if (sort.length > 0) {
          const { field, dir } = sort[0];
          if (dir === 'asc') {
              return a[field] > b[field] ? 1 : -1;
          } else if (dir === 'desc') {
              return a[field] < b[field] ? 1 : -1;
          }
      }
      return 0;
    });

    var items = result;
    for (let i = 0; i < items.length; i++) {
      items[i].contador = i + 1;             
    }

    return result;
  }, [librariesGrid, sort, filterInput]);

  const onSortChange = (event: any) => {
      setSort(event.sort);
  };

  const handleFilterChange = (event: GridFilterChangeEvent) => {
    const filteredData = filterBy(librariesGrid.data, event.filter);
    setLibrariesGrid({ data: filteredData, filter: event.filter });
  };

  const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={librariesGrid.data} expanded={true}/>
      </div>
    );
  };

  const clearInput = () => setFilterInput('');

  const DescriptionCell = (props: any) => {
    const { dataItem } = props;

    const navigateToAlias = () => {
      if (dataItem.ocorrencias > 0 && SearchText !== null && SearchText.length > 0) {
        window.open(`${window.location.origin}/${dataItem.name}/search/${SearchText}`, SearchText);
      } else {
        window.open(`${window.location.origin}/${dataItem.name}`, dataItem.name);
      }
    };

    return (
      <td className='descriptioncolumn' onClick={navigateToAlias} style={{textDecoration: 'underline dotted', textDecorationThickness: '1px', textUnderlineOffset: '4px', cursor: 'pointer'}}>
        {dataItem.description}
      </td>
    );
  }; 

  const sortDescription = () => {
    var ordem =!descOnOff;
    setDescOnOff(ordem);
    setSort( [{ field: 'description', dir: ordem ? 'desc' : 'asc'}]);
  }

  const DescricaoHeader = () => {
    return (
      <div>
        <span onClick={() => sortDescription()}>Descrição {sort.length>0 && sort[0].field===baseSort[0].field ? <> {descOnOff ? <SvgIcon icon={sortAscSmallIcon} /> : <SvgIcon icon={sortDescSmallIcon} /> } </> : <></>}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon}/> </span>
        <Input id='inputSearch'
          type="text"                    
          placeholder="Filtrar nomes de bibliotecas..."
          value={filterInput}
          onChange={(e) => {
            setFilterInput(e.value);
            setTimeout(() => {
                const inputElement = document.getElementById('inputSearch');
                if (inputElement) inputElement.focus();      
            }, 100);
          }}
          className="filterinput"
        />
        {filterInput && (
          <Button title="Limpar filtro" onClick={clearInput} className='filterclear'>
            <Icon name="close" />
          </Button>
        )}
      </div>
    )
  };

  return (
    <>
      {isMobile ?
        <>
            {Loading ?
                <span className='loader-mobile'>
                    <Loader type='converging-spinner' />
                </span>
            : <></>
            }

            <Grid className='DocMultiGrid-mobile' data={librariesSorted} sortable={true} sort={sort} size={'small'} onSortChange={onSortChange} onFilterChange={handleFilterChange} onRowClick={(e) => {}}>
              <Column headerCell={DescricaoHeader} cell={DescriptionCell} filter="text" filterTitle="Filtrar" filterable={true} sortable={true} 
                      className='descriptioncolumn-mobile' headerClassName='descriptioncolumnheader-mobile' field='description' />
              <Column sortable={true} className='matchcolumn-mobile' headerClassName='matchcolumnheader-mobile' field='ocorrencias' title='Ocor.' >
            </Column>
        </Grid>
        </>
      :
        <>
            {Loading ?
              <span className='loader'>
                  <Loader type='converging-spinner' />
              </span>
            : <></>
            }   

            <Grid className='DocMultiGrid' data={librariesSorted} sortable={true} sort={sort} size={'small'} onSortChange={onSortChange} onFilterChange={handleFilterChange} onRowClick={(e) => {}}>
              <Column headerCell={DescricaoHeader} cell={DescriptionCell} columnMenu={ColumnMenuCheckboxFilter} filter="text" 
                      filterTitle="Filtrar" filterable={true} sortable={true} className='descriptioncolumn' headerClassName='descriptioncolumnheader' field='description' />
              <Column sortable={true} className='pagecolumn' headerClassName='pagecolumnheader' field='pages' title='Páginas' />
              <Column sortable={true} className='matchcolumn' headerClassName='matchcolumnheader' field='ocorrencias' title='Ocorrências' />
            </Grid>
        </>
      }
    </>
  );
} 