import { ItemRenderProps, TreeView, TreeViewExpandChangeEvent, TreeViewItemClickEvent, processTreeViewItems} from '@progress/kendo-react-treeview';
import { folderIcon, folderOpenIcon, filterIcon, xIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import React, { useEffect, useRef } from 'react';
import { FoldersModel } from '../models/foldersModel';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { TelerikTreeModel } from '../models/telerikTreeModel';
import { Input } from '@progress/kendo-react-inputs';
import { findFolderById } from '../services/shared'; 
import { useSearchContext } from '../context/SearchContext';
import { isMobile } from 'react-device-detect';

interface FoldersTreeviewProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FoldersTreeview: React.FC<FoldersTreeviewProps> = ({ isVisible, setIsVisible }) => {
  const { Folders } = useFoldersContext();
  const { Folder, setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const { Search } = useSearchContext();
  const [ filterValue, setFilterValue ] = React.useState();
  const [ treeviewNodesRAW, setTreeviewNodesRAW ] = React.useState<TelerikTreeModel[]>([]);
  const [ treeviewNodes, setTreeviewNodes ] = React.useState<TelerikTreeModel[]>([]);

  // Carrega as pastas na treeview
  useEffect(() => {
    const mapFolders = (folders: FoldersModel[] | null, parentPath: string): TelerikTreeModel[] => {
      if (!folders) return [];
      
      return folders.map(folder => ({
        id: folder.Id,
        color: 'black',
        text: folder.Name,
        hasSubFolders: folder.HasSubFolders,
        parent: folder.Parent,
        items: mapFolders(folder.SubFolders, parentPath + '/' + folder.Name),
        path: parentPath + (parentPath === '' ? '' : '/') + folder.Name,
        pages: folder.Pages ?? 0,
        matchs: 0
      }));
    }

    setTreeviewNodes(mapFolders(Folders,""));
    setTreeviewNodesRAW(mapFolders(Folders,""));
  }, [Folders]);

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.expanded = !event.item.expanded;
  }

  // Seleciona uma pasta
  useEffect(() => {
    if (Folder?.Id != 0) {
      // Seleciona e expande o nó da treeview
      const ExpandAndSelectItem = (items: TelerikTreeModel[], id: number) => {
        if (items.length === 0) return false;
        let isSelected = false;

        items.forEach((item) => {
          item.selected = item.id === id;
          if (item.selected) isSelected = true;
          if (item.hasSubFolders) {        
            if (ExpandAndSelectItem(item.items ?? [], id)) {
              item.expanded = true;
              isSelected = true
            }
          }
        });

        return isSelected;
      };
      ExpandAndSelectItem(treeviewNodes, Folder?.Id ?? 0);

      // Scroll na treeview para certificar que o nó da pasta selecionada ficará visível    
      const scrollIntoView = () => {
        if (Folder?.Name) {
          let treeItemDoms = document.querySelectorAll('k-treeview');
          //console.log(treeItemDoms);
          if (treeItemDoms.length === 0) return;
          
          treeItemDoms.forEach((treeItemDom) => {
            if (treeItemDom.textContent?.startsWith(Folder?.Name)) {
              treeItemDom.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
        }
      }
      scrollIntoView();
    }
  }, [Folder])

  // Coloca as cores verde/azul e total de ocorrencias nas pastas após a pesquisa ou reseta para os icones pretos
  useEffect(() => {
    const resetTreeviewMatchs = (items: TelerikTreeModel[]) => {
      for (let item of items) {        
        item.color = 'black';
        item.matchs = 0;
        if (item.items) {
          resetTreeviewMatchs(item.items);
        }
      }
    }

    if (!Search) {   
      resetTreeviewMatchs(treeviewNodes);
    }
    else {
      const setTreeviewMatchs = (items: TelerikTreeModel[], id: number): boolean => {
        for (let item of items) {        
          if (item.id === id) {
            item.color = 'green';
            item.matchs += 1;
            return true;
          } 
          else if (Array.isArray(item.items)) {
            let found = setTreeviewMatchs(item.items, id);
            if (found) {
              if (item.matchs == 0) {
                item.color = 'blue';
                item.matchs = -1;
              }
              return found;
            }
          }          
        }

        return false;
      }
    
      resetTreeviewMatchs(treeviewNodes);

      Search?.matchs.forEach(match => {
        setTreeviewMatchs(treeviewNodes, match.folderID);
      });
    }
  }, [Search]);

  // Evento de clique no node da treeview
  const onItemClick = (event: TreeViewItemClickEvent) => {
    let folder: FoldersModel | null = findFolderById(Folders, event.item.id ?? 0);
    setFolder(folder);

    const firstMatchInFolder = Search?.matchs.find(x => x.folderID === folder?.Id);
    if (!firstMatchInFolder) {
      // Primeira página, pasta sem ocorrências
      setPage(folder?.Pages === 0 ? 0 : 1);
    }
    else {
      // Primeira pagina com ocorrência
      setPage(firstMatchInFolder?.page) 
    };

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  };

  const renderNode = (item: ItemRenderProps) => {
    const style = {color: item.item.color, marginRight: 5, strokeWidth: 10, stroke: item.item.color };
    const iconItem = item.item.selected ? folderOpenIcon : folderIcon;
    const text = item.item.matchs > 0 ? item.item.text + " (" + item.item.matchs + ")" : item.item.text

    return (
      <>
        <SvgIcon icon={iconItem} style={style} />
        {text}
      </>
    );
  };

  const onFilterChange = (ev: any) => {
    let term = ev.value;
    setFilterValue(term);

    let acc: any[] = [];

    const contains = (text: string, term: string) => text.toLowerCase().includes(term.toLowerCase());

    const search = (items: any[], term: string) => {
      let result: any[] = [];
      items.forEach(item => {
        if (contains(item.text, term)) {
          result.push(item);
        } else if (item.items && item.items.length > 0) {
          let newItems = search(item.items, term);
          if (newItems && newItems.length > 0) {
            result.push({
              text: item.text,
              items: newItems,
              expanded: true,
            });
          }
        }
      });
      return result;
    };

    treeviewNodesRAW.forEach(item => {
      if (contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        let newItems = search(item.items, term);
        if (newItems && newItems.length > 0) {
          acc.push({
            text: item.text,
            items: newItems,
            expanded: true,
          });
        }
      }
    });

    setTreeviewNodes(acc);
  };

  return (<>
    {isMobile ?
      <div className={isVisible ? 'leftpanel-mobile' : 'folderTreeViewHidden'} style={{ zIndex: 20000 }}>    
        <div className='leftpanelHeader-mobile'>
          <div className='leftpanelTitle-mobile'>Pastas</div>   
          <div onClick={()=> setIsVisible(false)} className='leftpanelClose-mobile'>
            <span><SvgIcon className='closeSvgIcon' icon={xIcon} /> </span>  
          </div>       
          <div className='leftpanelFilter'>
            <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon} /> </span>
            <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder='Filtrar...'/>
          </div>         
        </div>
        <div className='treeviewdiv-mobile'>
          <TreeView className='k-treeview-mobile'  
            aria-multiselectable={false} 
            animate={true} 
            expandIcons={true} 
            onItemClick={onItemClick} 
            item={renderNode}
            data={processTreeViewItems(treeviewNodes, {expand: { ids: [], idField: 'text' }})}
            onExpandChange={onExpandChange} />     
        </div>
      </div>     
      :
      <div className={isVisible ? 'leftpanel' : 'folderTreeViewHidden'} style={{ zIndex: 8000 }}>
        <div className='leftpanelHeader'>
          <div className='leftpanelTitle'>Pastas</div>
          <div onClick={() => setIsVisible(false)} className='leftpanelClose'>
            <span><SvgIcon className='closeSvgIcon' icon={xIcon} /> </span>
          </div>
          <div className='leftpanelFilter' >
            <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon} /> </span>
            <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder='Filtrar...'/>
          </div>
        </div>
        <div className='treeviewdiv'>
          <TreeView
            aria-multiselectable={false}
            animate={true}
            expandIcons={true}
            onItemClick={onItemClick}
            item={renderNode}
            data={processTreeViewItems(treeviewNodes, {expand: { ids: [], idField: 'text' }})} 
            onExpandChange={onExpandChange}
          />
        </div>         
      </div>
    }
    </>
  );
};
export default FoldersTreeview;