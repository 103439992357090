import { ExportFoldersModel } from '../models/exportPdfModel';  

export interface exportPdfResult {
  id: string;
  mensagem?: string
}

export const exportPdf = async ( library: string, quality: string, folders: ExportFoldersModel[], PDFComplianceLevel: string) : Promise<exportPdfResult> => {
  let result = {} as exportPdfResult;
  try {
    const baseUrl = process.env.REACT_APP_API_EXPORTPDF;    
    const url = new URL(`${baseUrl}/${library}`);

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },        
      body: JSON.stringify({jpgQuality : quality, PDFComplianceLevel : PDFComplianceLevel, folders: folders })   
    });

    if (!response.ok) {
      if (process.env.REACT_APP_SHOW_LOGS === 'on') {
        console.error('Erro ao enviar pedido: ' + response.statusText); 
      }
      result = {id: '', mensagem: 'Erro ao enviar pedido'}  as exportPdfResult
      return result;
    }      

    const id = await response.text(); 
    result = {id: id, mensagem: ''} as exportPdfResult;
  } 
  catch (error) {        
    if (process.env.REACT_APP_SHOW_LOGS === 'on') {
      console.error('Erro ao criar Pdf: ' + error);
    }
    result = {id: '', mensagem: 'Erro ao criar Pdf'}  as exportPdfResult
  }
  
  return result;
};

export const exportPdfStatus = async ( id: string) : Promise<string> => {
  var result = "";
  try {
    const baseUrl = process.env.REACT_APP_API_EXPORTPDF_STATUS;    
    const url = new URL(`${baseUrl}/${id}`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      console.error('Erro ao ler imagem: ' + response.statusText); 
      return result;
    }      

    result = await response.text(); 

  } 
  catch (error) {        
  }

  return result;
};
   
export const exportPdfDownloadUrl = (id: string) : string => {
  const baseUrl = process.env.REACT_APP_API_EXPORTPDF_DOWNLOAD;    
  const url = new URL(`${baseUrl}/${id}`);   
  return url.toString();
};
