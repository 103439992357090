import React, { ReactElement, useEffect } from 'react';
import { libraryApi } from '../apis/library';
import { useLibrariesContext } from '../context/LibrariesContext';
import { useLibraryContext } from '../context/LibraryContext';
import { useParams } from 'react-router-dom';
import DocReaderDrawerRouterContainer from './DocReader/DrawerRouterContainer';
import { DocReader } from '../pages/docreader';
import RedirectComponent from '../components/RedirectComponent';
import ErrorMessage from '../components/ErrorMessage';
import { Loader } from '@progress/kendo-react-indicators';
import DocMultiDrawerRouterContainer from './DocMulti/DrawerRouterContainer';
import { DocMulti } from '../pages/docmulti';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { isMobile } from 'react-device-detect';
import { findFolderByName, findFolderWithPage } from '../services/shared';
import { Helmet } from 'react-helmet-async';

type RouteParams = {
  library: string;
  searchText?: string;
  folder?: string;
  page?: string;
  pagfis?: string;
};

export const BaseDocPro = (): ReactElement | null => {
  const { library, searchText, folder, page, pagfis } = useParams<RouteParams>();
  const [ cDefaultBiblioteca, setCDefaultBiblioteca ] = React.useState<string>(library !== undefined ? library : (process.env.REACT_APP_DEFAULT_BIBLIOTECA + ''));
  const { Libraries } = useLibrariesContext();
  const { Library, setLibrary } = useLibraryContext();
  const [ loading, setLoading ] = React.useState<boolean>(Library?.type === undefined || cDefaultBiblioteca === 'demo');     
  const { Folders } = useFoldersContext();
  const { setSearchText } = useSearchTextContext();
  const [ error, setError ] = React.useState<boolean>(false);
  const { setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const [ title, setTitle ] = React.useState<string>("DocReader Web");

  useEffect(() => {
    if (cDefaultBiblioteca === '') {
      setCDefaultBiblioteca(process.env.REACT_APP_DEFAULT_BIBLIOTECA + '');
    }

    if (library !== cDefaultBiblioteca) {
      if (library !== '') { setCDefaultBiblioteca(library!); }

      setLoading(true);
    }
  }, [library]);

  useEffect(() => {
    if (Library === null) {
      const loadingX = loading;
      if (loadingX) {
        const bib = cDefaultBiblioteca !== '' ? cDefaultBiblioteca : process.env.REACT_APP_DEFAULT_BIBLIOTECA + '';
        
        const fetchData = async () => {
          const result = await libraryApi(bib ?? '');
          if (result.sucesso) {
            setCDefaultBiblioteca(cDefaultBiblioteca);
            setLibrary(result.data!);
            setTitle((result.data?.description.trim() === "" ? result.data?.name + " - " : result.data?.description + " - ") + "DocReader");
          }
          else {
            setError(true);
          };
        };

        fetchData();
      }
    }
  }, [loading, Library]);
  
  useEffect(() => {
    if (Folders === null || Folders === undefined) return;

    // Se entrou com pesquisa na URL, faz a pesquisa
    if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
      setSearchText(searchText);
      return;
    }
    
    // Se entrou com uma pasta na URL procura a pasta
    let foldertmp = findFolderByName(Folders, folder?.trim() || '');
    let pagetmp = parseInt(page ?? "1");

    if (foldertmp !== null) {
      if (pagetmp < 0) pagetmp = 1
      if (pagetmp > foldertmp.Pages) pagetmp = foldertmp.Pages
    }
    else {
      foldertmp = findFolderWithPage(Folders) ?? Folders[0];
    }

    setFolder(foldertmp); 
    setPage((foldertmp.Pages === 0 ? 0 : pagetmp));
    
    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  }, [Folders]);

  useEffect(() => {
    if (Libraries === null || Libraries === undefined) return;

    if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
      // Se entrou com pesquisa na URL
      setSearchText(searchText);
    }
  }, [Libraries]);

  return (
    <>
      <RedirectComponent library={cDefaultBiblioteca} />

      {Library === null ? 
        <>
            {error ?
              <ErrorMessage />
            :
              <span className={isMobile ? 'loader-mobile' : 'loader'}>
                <Loader type='converging-spinner' />
              </span>
            }
        </>
      :
        <>
          <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name='description' content={title}  />
            <meta property="og:url" content={window.location.href} />
            <meta property='og:title' content={title} />
            <meta property="og:description" content={title} />
            <meta property="og:image" content="/images/docpro.png" />
          </Helmet>

          {Library.type === 1 ?
            <DocMultiDrawerRouterContainer library={cDefaultBiblioteca}>
              <DocMulti library={cDefaultBiblioteca} />
            </DocMultiDrawerRouterContainer>
          :
            <DocReaderDrawerRouterContainer library={cDefaultBiblioteca}>
              <DocReader library={cDefaultBiblioteca} />
            </DocReaderDrawerRouterContainer>
          }
        </>
      }
    </>
  );
};