import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FoldersContextProvider } from './context/FoldersContext';
import { FolderContextProvider } from './context/FolderContext';
import { LibrariesContextProvider } from './context/LibrariesContext';
import { LibraryContextProvider } from './context/LibraryContext';
import { PageContextProvider } from './context/PageContext';
import { PageLoadContextProvider } from './context/PageLoadContext';
import { MessageBoxContextProvider } from './context/MessageBoxContext';
import { LoadingContextProvider } from './context/LoadingContext';
import { ControlContextProvider } from './context/ControlContext';
import { SearchContextProvider } from './context/SearchContext';
import { SearchTextContextProvider } from './context/SearchTextContext';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { BaseDocPro } from './components/BaseDocPro';
import './App.scss';
import ptMessages from './pt';

loadMessages(ptMessages, 'pt-PT');

const App = () => {
  return (
    <div className='App'>
      <LocalizationProvider language="pt-PT">
        <LoadingContextProvider>
            <MessageBoxContextProvider>
              <SearchTextContextProvider>
                <LibrariesContextProvider>
                  <LibraryContextProvider>
                    <FoldersContextProvider>
                      <SearchContextProvider>
                        <FolderContextProvider>
                          <ControlContextProvider>
                            <PageContextProvider>
                              <PageLoadContextProvider>
                                <BrowserRouter basename='/'>
                                  <HelmetProvider>
                                    <Routes>
                                      <Route path='/:library/folder/:folder/page/:page/search/:searchText' element={<BaseDocPro />} />
                                      <Route path='/:library/folder/:folder/page/:page' element={<BaseDocPro />} />
                                      <Route path='/:library/folder/:folder/search/:searchText' element={<BaseDocPro />} />
                                      <Route path='/:library/folder/:folder' element={<BaseDocPro />} />
                                      <Route path='/:library/pagfis/:pagfis' element={<BaseDocPro />} />
                                      <Route path='/:library/pagfis/:pagfis/search/:searchText' element={<BaseDocPro />} />
                                      <Route path='/:library/search/:searchText' element={<BaseDocPro />} />
                                      <Route path='/:library' element={<BaseDocPro />} />
                                      <Route path='/' element={<BaseDocPro />} />
                                    </Routes>
                                  </HelmetProvider>
                                </BrowserRouter>
                              </PageLoadContextProvider>
                            </PageContextProvider>
                          </ControlContextProvider>
                        </FolderContextProvider>
                      </SearchContextProvider>
                    </FoldersContextProvider>
                  </LibraryContextProvider>
                </LibrariesContextProvider>
              </SearchTextContextProvider>
            </MessageBoxContextProvider>
        </LoadingContextProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;