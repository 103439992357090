import React, { useEffect } from 'react';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import ImageDraw from '../components/DocumentDraw';  
import { buildHash, getImage } from '../apis/imagem'; 
import { ImageModel } from '../models/imageModel';
import { useSearchContext } from '../context/SearchContext';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';  
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from '@progress/kendo-react-indicators';
import { useLoadingContext } from '../context/LoadingContext';
import { FoldersModel } from '../models/foldersModel';
import { isMobile } from 'react-device-detect';
import { searchApi } from '../apis/search';
import FolderEmpty from "../assets/FolderEmpty.svg"

interface DocReaderProps {
  library: string;
}

export const DocReader: React.FC<DocReaderProps> = ({ library }) => {       
  const [ image, setImage ] = React.useState<ImageModel | null>(null);
  const [ cachedImages, setCachedImages ] = React.useState<ImageModel[]>([]);
  const { Folder } = useFolderContext();
  const { Page } = usePageContext();
  const { PageLoad, setPageLoad } = usePageLoadContext();
  const { setSearch } = useSearchContext();
  const { SearchText } = useSearchTextContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { Loading, setLoading } = useLoadingContext();

  // Hook para atualizar a Pagina na tela
  useEffect(() => {
    // Carrega a imagem
    const fetchImage = async (folder: FoldersModel, page: number) : Promise<boolean> => {
      try {
        if (folder?.Id === 0 || page === 0) return false;

        setLoading(true);

        const hashCurrent = buildHash(SearchText ?? '', library ?? (process.env.REACT_APP_DEFAULT_BIBLIOTECA ?? ''), folder?.Id, page);

        //// Pega a imagem do cache, quando já acessou antes
        const cachedImage = cachedImages.find(h => h.hash === hashCurrent);
        if (cachedImage) {
          setImage(cachedImage);
          setLoading(false);
          return false;
        }

        // Pega a imagem do servidor
        const resultImage = await getImage(hashCurrent, library ?? (process.env.REACT_APP_DEFAULT_BIBLIOTECA ?? ''), folder?.Id, page);

        setLoading(false);

        if (resultImage === null) return false;        

        if (!resultImage.sucesso) {
          setImage(null);         
          setMessageBox(resultImage.mensagem ?? 'Falha geral');
          return false;
        }        

        if (resultImage.hash === undefined) return false;       
          
        setCachedImages(cachedImages => [...cachedImages, resultImage]);

        setImage(resultImage);        

        return true;
      } 
      catch (error) {
        setLoading(false);
        console.error('Error fetching image:', error);        
        return false;
      }
    }

    if (!PageLoad) return;

    setTimeout(() => {
      setPageLoad(false);
    }, 100);

    // Pasta sem páginas
    if (Folder === null || Page === null || Page === 0) {
      setImage({base64: FolderEmpty} as ImageModel);
      return;
    }

    fetchImage(Folder, Page);
  }, [PageLoad]);

  // Hook para fazer a pesquisa
  useEffect(() => {
    const search = async () : Promise<boolean> => {
      try {
        if (SearchText === '' || SearchText === null || SearchText === undefined) {          
          window.history.replaceState(null, "", "/" + library);
          return false;
        }

        setLoading(true);
        let searchTmp = await searchApi(library ?? '', SearchText);
        setLoading(false);

        if (searchTmp.status === 'ok') {
          if (searchTmp.pagesCount > 0) {
            searchTmp.matchs.sort((a, b) => a.folderID - b.folderID || a.page - b.page); //Ordena as ocorrencias por folder/page
            setSearch(searchTmp);
            window.history.replaceState(null, "", "/" + library + "/search/" + SearchText);
          }
          else {
            setSearch(null);
            setMessageBox("Sem ocorrências");
            window.history.replaceState(null, "", "/" + library);
          }
        }
        else {
          setSearch(null);
          setMessageBox("Sem ocorrências");
          window.history.replaceState(null, "", "/" + library);
        }
      } catch (error) {
        setSearch(null);
        setMessageBox("Erro na pesquisa");
      }

      return true;
    }

    search()
  }, [SearchText]);

  const closeDialog = () => { 
    setMessageBox(null);
  };

  return (
    <>
    {isMobile ?
      <>
        {Loading ?
          <span className='loader-mobile'>
            <Loader type='converging-spinner' />                    
          </span>
          : <></>
        }   

        <div id='docpro' className='maindiv'>
          <ImageDraw image={image ?? undefined}/> 
        </div>    

        {messageBox !== null && (
          <Dialog onClose={closeDialog} width={300} height={400} closeIcon={true} title={"Aviso"} themeColor='primary' >
            <div className='messageboxdiv' >
              {messageBox}
            </div>
          </Dialog>
        )}
      </>
    :
      <>
        {Loading ?
          <span className='loader'>
            <Loader type='converging-spinner' />                    
          </span>
          : <></>
        }   

        <div id='docpro' className='maindiv'>
          <ImageDraw image={image ?? undefined} /> 
        </div>    

        {messageBox !== null && (
          <Dialog onClose={closeDialog} width={450} height={200} closeIcon={true} title={"Aviso"} themeColor='primary' >
            <div className='messageboxdiv' >
              {messageBox}
            </div>
          </Dialog>
        )}
      </>
    }
    </>
);
} 