import React, { useEffect } from 'react';
import SearchBox from './SearchBox';
import { useLibraryContext } from '../../context/LibraryContext';
import { useControlContext } from '../../context/ControlContext';
import { isMobile } from 'react-device-detect';
import SearchIcon from '../../assets/SearchIcon.svg';
import MatchsBox from '../MatchsBox';

interface HeaderProps {
  isSearch: boolean;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header: React.FC<HeaderProps> = ({ isSearch, setIsSearch }) => {
  const { commandControlContext } = useControlContext();
  const { Library } = useLibraryContext();
  const [ docMultiMatchsTotal, setDocMultiMatchsTotal ] = React.useState<number>(0);
  const [ headerImgStyle, setHeaderImgStyle ] = React.useState<React.CSSProperties>({});
  const [ isSearchBoxVisible, setIsSearchBoxVisible ] = React.useState<boolean>(false);

  useEffect(() => {
    if (commandControlContext?.DocMultiMatchsTotal !== undefined) {
      setDocMultiMatchsTotal(commandControlContext.DocMultiMatchsTotal);
    }
  }, [commandControlContext]);

  useEffect(() => {
    if (Library && Library?.headerImg?.contentType !== undefined && Library?.headerImg?.fileContents !== undefined) {
      setHeaderImgStyle({
        backgroundImage: `url(data:${Library.headerImg.contentType};base64,${Library.headerImg.fileContents})`,
        backgroundRepeat: 'repeat'
      });
    }    
  }, [Library]);

  const logoImg = () => {
    if (Library === null || Library.logoImg === null || Library?.logoImg?.fileContents === undefined) {
      return 'unset';
    }
    return `url(data:${Library.logoImg.contentType};base64,${Library!.logoImg.fileContents})`;
  }

  const logoImgStyle = {
    backgroundImage: logoImg(),
    backgroundRepeat: 'no-repeat',
    width: '300px',
    height: '60px',
    cursor: 'pointer'
  };

  const openUrl = () => {
    if (Library !== null) {
      window.open(Library.logoUrl, Library.name);
    };
  }

  return (
    <>
      {isMobile ? 
        <>
          <SearchBox isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible}/>

          <header className={`header-docmulti-mobile`} style={headerImgStyle}>
            <div className='headerleft-mobile' style={logoImgStyle} onClick={() => openUrl()} />

            <div className={`headermiddle-mobile`}>
              {docMultiMatchsTotal === 0 ? <></> :
                <div className='matchsdiv-mobile'>
                  <div className='matchstitle-mobile'>Ocorrências</div>
                  <div className='matchsnumber-mobile'>{docMultiMatchsTotal.toLocaleString('pt-BR')}</div>
                </div>
              }
            </div>

            <div className='headerright-mobile' onClick={()=> setIsSearchBoxVisible(true)}>
              <img src={SearchIcon} className='searchicon-mobile' alt="Search" />
            </div>
          </header>
        </>
      :    
        <header className='header-docmulti' style={headerImgStyle}>
          <div className='headerleft' style={logoImgStyle} onClick={() => openUrl()} />

          <div className='headermiddle'>
            <div className='navigationbox'>
              <div>
                <SearchBox isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible} />
              </div>
              <div className='baseInfoAcervo'>
                {docMultiMatchsTotal === 0 ? <></> :
                  <div className='matchsdiv-docmulti'>
                    <div className='matchslabel-docmulti'>Ocorrências:</div>
                    <div className='matchstotal-docmulti'>{docMultiMatchsTotal.toLocaleString('pt-BR')}</div>
                  </div>
                }
                <div className='librariesdiv-docmulti'>
                  <div className='librarieslabel-docmulti'>Acervos:</div>
                  <div className='librariestotal-docmulti'>{Library === null ? '0' : Library?.listLibrariesTotal?.toString()}</div>
                </div>
                <div className='pagesdiv-docmulti'>
                  <div className='pageslabel-docmulti'>Páginas:</div>
                  <div className='pagestotal-docmulti'>{Library === null ? '0' : Library?.listLibraries?.reduce((sum, libraryItem) => sum + libraryItem.pages, 0)?.toLocaleString('pt-BR')}</div>
                </div>
                <div className='clear'></div>
              </div>
              <div className='clear'></div>
            </div>
          </div>
          <div className='headerright'>
            <div className='docprologo' onClick={() => window.open("https://www.docpro.com.br")} />
          </div>
        </header>
    }
  </>
  );
};

Header.displayName = 'Header';