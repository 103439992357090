import * as React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { useLibraryContext } from '../../context/LibraryContext';
import { isMobile } from 'react-device-detect';

const DocMultiDrawerRouterContainer = (props: any) => {
  const children = props.children;  
  const [ isSearch, setIsSearch ] = React.useState(false);
  const { Library } = useLibraryContext();

  return (
    <>
      <Header isSearch={isSearch} setIsSearch={setIsSearch} />

      <div className='bodyMulti'>
        <div className='blockContent'>
          {children}
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
}

export default DocMultiDrawerRouterContainer;