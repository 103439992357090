import { ImageModel } from '../models/imageModel';

export const buildHash = (key: string, 
  library: string, 
  folderId: number, 
  page: number) : string => { return `${key}_${library}_${folderId}_${page}`; };

export const getImage = async ( hash: string, library: string, folderId: number, page: number ) : Promise<ImageModel | null> => {
    var result = { base64: '', hash: undefined, sucesso: false } as ImageModel;
    const baseUrl = process.env.REACT_APP_API_URL_DOCUMENTS;
    if (!baseUrl) {
      console.error('Environment variable REACT_APP_API_URL_DOCUMENTS is not set');
      return result;
    }
    
    if (folderId === 0) { return null; }
    if (page === 0) { return null; }

    try {
      const url = new URL(`${baseUrl}/${library}/${folderId}/${page}`);       

      result.hash = hash;
      let response = await fetch(url.toString(), {method: 'GET', headers: {'Content-Type': 'application/json'}});

      if (!response.ok) {
        result.mensagem = 'Erro ao ler imagem: ' + response.statusText;
        result.hash = undefined;
        return result;
      }               

      const blob = await response.blob();
      const bmp = await createImageBitmap(blob);

      const reader = new FileReader();
      reader.onloadend = function() {
         let image = reader.result as string;
         if (image === undefined) {
           result.sucesso = false;
           result.mensagem = "page_not_found";
           return result;
         }
         else {
            result.base64 = image;
            result.width = bmp.width;
            result.height = bmp.height;
            const originalSize = response.headers.get("OriginalSize")
            result.widthOriginal = parseInt(originalSize?.split(",")[0].substring(2) ?? '0');
            result.heightOriginal = parseInt(originalSize?.split(",")[1].substring(2) ?? '0');
            result.sucesso = true;
        }   
      }
      reader.readAsDataURL(blob);
    } 
    catch (error) {
      result.sucesso = false;
      result.mensagem = error as string;
    } 

    let nCount = 0;
    while(result.base64 === '' && !result.mensagem) {
      nCount++; 
      await new Promise(r => setTimeout(r, 100));
      if (nCount > 50) {        
        result.hash = undefined;
        result.sucesso = false;
        break;
      }
    }
    return result;
};