import * as React from 'react';
import { SvgIcon } from '@progress/kendo-react-common'; 
import { xIcon } from '@progress/kendo-svg-icons'; 
import { Checkbox, RadioButton, NumericTextBox  } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { ExportFoldersModel } from '../models/exportPdfModel';
import { exportPdf, exportPdfStatus, exportPdfDownloadUrl } from '../apis/exportpdf'; 
import { useSearchContext } from '../context/SearchContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { FoldersModel } from '../models/foldersModel';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { findLastSubFolder } from '../services/shared'

interface ExportPdfProps {
  library: string; 
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExportPdfControl: React.FC<ExportPdfProps> = ({ library, isVisible, setIsVisible}) => { 
  const { Search } = useSearchContext();
  const { Folder } = useFolderContext();
  const { Page } = usePageContext();
  const { setMessageBox } = useMessageBoxContext();
  const [ idExporting, setIdExporting ] = React.useState<string>(''); 
  const [ progress, setProgress ] = React.useState<number>(0);
  const [ isVisibleProgress, setIsVisibleProgress] = React.useState<boolean>(false);
  const [ range, setRange ] = React.useState<number>(1); //1 - Pagina de n a n, 2 - Todas Paginas da pasta, 3 - Todas Ocorrências e 4 - Ocorrências só da pasta atual
  const [ pagIni, setPagIni ] = React.useState<number>(Page ?? 0);
  const [ pagEnd, setPagEnd ] = React.useState<number>(Page ?? 0);
  const [ subFolders, setSubFolders ] = React.useState<boolean>(false);
  const [ subFoldersMatchs, setSubFoldersMatchs ] = React.useState<boolean>(false);
  const [ qualidade, setQualidade ] = React.useState<number>(0.7);
  const [ PDFComplianceLevel, setPDFComplianceLevel ] = React.useState<string>("PDF");
  const [ isVisibleDownload, setIsVisibleDownload] = React.useState<boolean>(false);
  const [ downloadUrl, setDownloadUrl ] = React.useState<string>('');

  const hasMatchs = (Search) ? true : false

  async function exportRun() {
    let exportfolders: ExportFoldersModel[] = [];
    const mapFolders = (folder: FoldersModel | null) => {          
      for (let pag=1; pag<=(folder?.Pages ?? 0); pag++) {
        exportfolders.push({ID: folder?.Id.toString() ?? '', Pages: pag.toString()});
      }

      if (subFolders) {
        if (folder?.SubFolders) {
          folder?.SubFolders.forEach((item) => {mapFolders(item)})
        }
      }
    }

    // Monta a lista de pastas e páginas de acordo com o intervalo escolhido
    switch(range) {
      // Paginas de/até 
      case 1: {
        if (pagIni > 0 && pagEnd > 0) {
          for (let pag=pagIni; pag<=pagEnd; pag++) {
            exportfolders.push({ID: Folder?.Id.toString() ?? '', Pages: pag.toString()});
          }
        }
        break;
      }

      // Todas as paginas da pasta atual, opcional de incluir as subpastas
      case 2: {
        mapFolders(Folder);
        break;
      }

      // Todas as ocorrências
      case 3: {
        Search?.matchs.forEach((match) => {
          exportfolders.push({ID: match?.folderID.toString() ?? '', Pages: match.page.toString()});
        });
        break;
      }

      // Todas as ocorrências da pasta atual, opcional de incluir as ocorrências das subpastas
      case 4: {
        if (Folder !== null) 
        var firstFolder = 0;
        var lastFolder = 0;

        firstFolder = Folder?.Id ?? 1;
        lastFolder = firstFolder;
        if (subFoldersMatchs) lastFolder = findLastSubFolder(Folder, firstFolder) ?? firstFolder;

        Search?.matchs.forEach((match) => {
          if (match.folderID >= firstFolder && match.folderID <= lastFolder) {
          exportfolders.push({ID: match?.folderID.toString() ?? '', Pages: match.page.toString()});
          }
        });
        break;
      }      
    }

    if (exportfolders.length === 0) {
      setMessageBox('Não existem páginas para gerar um PDF');
      return;
    }

    await exportPdf(library, (qualidade * 100).toString(), exportfolders, PDFComplianceLevel).then((result) => { 
      if (result.mensagem === '') {
        setIdExporting(result.id);
        setProgress(0);
        setIsVisibleProgress(true);
        setIsVisibleDownload(false);
      }
      else {
        setMessageBox(result.mensagem ?? '');
      }  
    })
  };

  React.useEffect(() => {
    if (idExporting.trim() === '') return;

    const timer = setInterval(async () => {
      await exportPdfStatus(idExporting).then((status) => {      
        if (status === 'done') {
          setProgress(100);
          clearInterval(timer);           
          setDownloadUrl(exportPdfDownloadUrl(idExporting));
          setIsVisibleDownload(true);
          setIdExporting('');
          setIsVisibleProgress(false);
        } 
        else {
          setProgress(parseInt(status));
        }
      });
    }, 500);

    return () => clearInterval(timer);
  }, [idExporting]);

  const downloadPDF = () => {
    var a = document.createElement('a'); 
    a.href = downloadUrl;  
    document.body.appendChild(a); 
    a.click();
    document.body.removeChild(a);
  }

  return (
    <div className={isVisible ? 'leftpanel': 'exportPdfHidden'}>
      <div className='leftpanelHeader'>                  
        <div className='leftpanelTitle'>Downloads</div>
          <div onClick={()=> setIsVisible(false)} className='leftpanelClose'>
            <span><SvgIcon className='closeSvgIcon' icon={xIcon} /> </span>  
          </div>       
      </div>                  
      <div className='exportPdf'>
        <RadioButton className='exportradio' label="Páginas de&nbsp;&nbsp;" checked={range === 1} onChange={(e) => {setRange(1);}}/>

        <NumericTextBox className='pageinput' defaultValue={pagIni} min={1} max={Folder?.Pages} step={1} value={pagIni} width={80} onChange={(e) => 
            setPagIni(e.value === null ? pagIni : e.value)}/>

        <span>&nbsp;&nbsp;até&nbsp;&nbsp;</span>

        <NumericTextBox className='pageinput' defaultValue={pagEnd} min={pagIni} max={Folder?.Pages} step={1} value={pagEnd} width={80} onChange={(e) => 
            setPagEnd(e.value === null ? pagEnd : e.value)}/>

        <br />
        <br />

        <RadioButton className='exportradio' label="Toda a pasta atual" checked={range === 2} onChange={(e) => {setRange(2);}}/>
        <br />
        <Checkbox className='subfolderchk' label="Incluir subpastas" checked={subFolders} disabled={range !== 2} onChange={(e) => setSubFolders(e.value)} />        
        <br />
        <RadioButton className='exportradio' disabled={!hasMatchs} label="Todas as ocorrências" checked={range === 3} onChange={(e) => {setRange(3);}}/>
        <br />
        <br />
        <RadioButton className='exportradio' disabled={!hasMatchs} label="Ocorrências da pasta atual" checked={range === 4} onChange={(e) => {setRange(4);}}/>
        <br />
        <Checkbox className='subfolderchk' disabled={!hasMatchs || (range !== 4)} label="Incluir subpastas" checked={subFoldersMatchs} onChange={(e) => setSubFoldersMatchs(e.value) }/>
        <br />
        <br />
        <div className='center'>
          Qualidade: &nbsp;
          <NumericTextBox  className='pageinput' format="p" defaultValue={qualidade} min={0.1} max={1} step={0.05} value={qualidade} width={80} onChange={(e) => 
            setQualidade(e.value === null ? .7 : e.value) }/>
          <br />
          <br />
          <br />
          <RadioButton className='exportradio' label="PDF" checked={PDFComplianceLevel === "PDF" ? true : false} onChange={() => {setPDFComplianceLevel("PDF");}}/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <RadioButton className='exportradio' label="PDF/A" checked={PDFComplianceLevel === "PDFA3B" ? true : false} onChange={() => {setPDFComplianceLevel("PDFA3B");}}/>
          <br />
          <br />
          <div className='progressdiv'>
            {isVisibleProgress ?  
              <ProgressBar className='progressbar' value={progress} labelPlacement='center' /> : 
              <></>
            }
          </div>
          <br/>
          <br/>
          <Button themeColor={"primary"} className='exportbutton' disabled={isVisibleProgress} onClick={() => exportRun()}>Gerar</Button>           
          <br />
          <br />
          <br />
          {isVisibleDownload ? <Button className='downloadbutton' onClick={()=>{downloadPDF()}}>Baixar PDF</Button> : <></>}
          </div>
        </div>
      </div>
  )
};
