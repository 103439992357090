import axios from 'axios';
import { LibraryBaseModel } from '../models/librariesModel';

export const libraryApi = async (
  library: string
): Promise<LibraryBaseModel> => {
 
  const baseUrl = process.env.REACT_APP_API_URL_LIBRARY;
  const url = new URL(`${baseUrl}/${library}`);

  var result: Promise<LibraryBaseModel> = axios.get(url.toString())
  .then(response => {                  
      var rowData = response.data as LibraryBaseModel;                  
      if (rowData.sucesso) {        
        return rowData;
      }
      return { mensagem: rowData.mensagem, data: null } as LibraryBaseModel;
  })
  .catch(error =>          
      { 
        if (process.env.REACT_APP_SHOW_LOGS === 'on') {
          console.error(error);
        }

        if (error.response && error.response.status === 422) {
          return { mensagem: '-422', data: null } as LibraryBaseModel;
        }
        else if (error.response && error.response.status === 404) {
          return { mensagem: '-404', data: null } as LibraryBaseModel;
        }

        return { mensagem: '-1', data: null } as LibraryBaseModel;
      }          
  );
  return result;
};