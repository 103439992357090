import axios from 'axios';
import { SearchBaseModel } from '../models/searchModel';
import { LibrariesListModel } from '../models/librariesModel';

export const docmultiSearchApi = async (libraries: LibrariesListModel[], textSearch: string): Promise<LibrariesListModel[]> => {
  const baseUrl = process.env.REACT_APP_API_URL_SEARCH;
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_API_URL_SEARCH is not set');
    return libraries;
  }
  
  await Promise.all(libraries.map(library => {
    let url = `${baseUrl}/${library.name}/${textSearch}`;

    if (process.env.REACT_APP_SHOW_LOGS === 'on') {
      console.log('Search api:: url: ' + url, 'textSearch:' + textSearch);
    }
    
    return axios.get(url)
      .then(response => {
        var rowData = response.data as SearchBaseModel;
        library.ocorrencias = rowData.data.pagesCount as number;
      })
      .catch(error => {
        console.error('API call failed for library:', library.name, error);
      });
  }));

  return libraries;
};