import React, { useEffect } from 'react';
import { useLibraryContext } from '../../context/LibraryContext';
import { isMobile } from 'react-device-detect';

export const Footer = () => {
  const { Library } = useLibraryContext();
  const [ footerImgStyle, setFooterImgStyle ] = React.useState<React.CSSProperties>({});

  useEffect(() => {
    if (Library && Library?.headerImg?.contentType !== undefined && Library?.headerImg?.fileContents !== undefined) {
      setFooterImgStyle({
        backgroundImage: `url(data:${Library.headerImg.contentType};base64,${Library.headerImg.fileContents})`,
        backgroundRepeat: 'repeat'
      });
    }    
  }, [Library]);

  return (
    <>
      {isMobile ? 
        <div className='footer' style={footerImgStyle}>
            <div className='left'>
              <div className='infoFooter'>
                Acervos:&nbsp;{Library?.listLibraries.length}
              </div>
              <br />
              <div className='infoFooter'>
                Páginas:&nbsp;{Library?.listLibraries?.reduce((sum, libraryItem) => sum + libraryItem.pages, 0)?.toLocaleString('pt-BR')}
              </div>
            </div>

          <div className='right'>
              <div className='docprologo-docmulti' onClick={() => window.open("https://www.docpro.com.br")} />
          </div>
        </div>
      :
      <></>
      }
    </>
  );
};

Footer.displayName = 'Footer';