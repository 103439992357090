import React from 'react';

const SharedSocialButtons: React.FC = () => {

    const url : string = window.location.href;

    return (
        <div className='blockShareButtons'>            
            <a title='Compartilhar no Facebook' href={`https://www.addtoany.com/add_to/facebook?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
                <img alt='Facebook' src='https://static.addtoany.com/buttons/facebook.svg' width='16' height='16' style={{backgroundColor: '#0E0E0E'}}/>
            </a>
            <a title='Compartilhar no E-mail' href={`https://www.addtoany.com/add_to/email?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
                <img alt='e-mail' src='https://static.addtoany.com/buttons/email.svg' width='16' height='16' style={{backgroundColor: '#0E0E0E'}}/>
            </a>
            <a title='Compartilhar no WhatsApp' href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
                <img alt='whatsapp' src='https://static.addtoany.com/buttons/whatsapp.svg' width='16' height='16' style={{backgroundColor: '#0E0E0E'}}/>
            </a>
            <a title='Compartilhar no LinkedIN' href={`https://www.addtoany.com/add_to/linkedin?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
                <img alt='linkedIn' src='https://static.addtoany.com/buttons/linkedin.svg' width='16' height='16' style={{backgroundColor: '#0E0E0E'}}/>
            </a>
            <a title='Compartilhar no X'  href={`https://www.addtoany.com/add_to/twitter?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
                <img alt='X' src='https://static.addtoany.com/buttons/x.svg' width='16' height='16' style={{backgroundColor: '#0E0E0E'}}/>
            </a>
        </div>
    );
};

export default SharedSocialButtons;