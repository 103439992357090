import React from 'react';

const ErrorMessage = (props: any) => {
    return (
        <>
        <div className='error'>
            Biblioteca não encontrada
        </div>
        </>
  );
}

export default ErrorMessage;