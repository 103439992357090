import React, { useEffect } from 'react';
import PageNavigatorBox from '../PageNavigatorBox';
import SearchBox from './SearchBox';
import MatchsBox from '../MatchsBox';
import { useLibraryContext } from '../../context/LibraryContext';
import { isMobile } from 'react-device-detect';
import SearchIcon from '../../assets/SearchIcon.svg';
import { useFolderContext } from '../../context/FolderContext';
import { useSearchContext } from '../../context/SearchContext';
import { paddingBottomIcon, paddingLeftIcon } from '@progress/kendo-svg-icons';

interface HeaderProps {
  isSearch: boolean;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header: React.FC<HeaderProps> = ({ isSearch, setIsSearch }) => {
  const { Library } = useLibraryContext();
  const [ headerImgStyle, setHeaderImgStyle ] = React.useState<React.CSSProperties>({});
  const [ isSearchBoxVisible, setIsSearchBoxVisible ] = React.useState<boolean>(false);
  const { Folder } = useFolderContext();
  const { Search } = useSearchContext();

  useEffect(() => {
    if (Library && Library?.headerImg?.contentType !== undefined && Library?.headerImg?.fileContents !== undefined) {
      setHeaderImgStyle({
        backgroundImage: `url(data:${Library.headerImg.contentType};base64,${Library.headerImg.fileContents})`,
        backgroundRepeat: 'repeat',
      });
    } 
  }, [Library]);

  const logoImg = () => {
    if (Library === null || Library.logoImg === null || Library?.logoImg?.fileContents === undefined) {
      return 'unset';
    }
    return `url(data:${Library.logoImg.contentType};base64,${Library!.logoImg.fileContents})`;
  }

  const logoImgStyle = {
    backgroundImage: logoImg(),
    backgroundRepeat: 'no-repeat',
    width: '300px',
    height: '60px',
    cursor: 'pointer',
  };

  const logoUrl = () => {
    if (Library !== null) {
      window.open(Library.logoUrl, Library.name);
    };
  }

  return (
    <>
      <div className='header' style={headerImgStyle}>
        {isMobile ? 
          <>
            <SearchBox isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible} />

            <header className={`header-mobile`}>
              <div className='headerleft-mobile'/>

              <div className={`headermiddle-mobile`}>
                <div className={`navigationbox-mobile`}>
                  <div className="right mobile-right">
                      <PageNavigatorBox />
                  </div>
                              
                  {Search?.pagesCount ?? 0 > 0 ? <MatchsBox /> : <></>}
                </div>
              </div>

              <div className='headerright-mobile' onClick={()=> setIsSearchBoxVisible(true)}>
                <img src={SearchIcon} className='searchicon-mobile' alt="Search" />
              </div>
            </header>
          </>
        :    
          <>
            <div className='header-desktop'>
              <div className='headerleft' style={logoImgStyle} onClick={() => logoUrl()}>{(Library?.logoImg === null) ? Library?.description : ''}</div>

              <div className='headermiddle'>
                <div className='navigationbox'>
                  <div>
                    <SearchBox isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible} />
                  </div>
                  <div className="matchboxdiv">
                    {Search?.pagesCount ?? 0 > 0 ? <MatchsBox /> : <></>}
                  </div>
                  <div className="pagenavigationboxdiv">
                    <PageNavigatorBox />
                  </div>
                </div>
              </div>

              <div className='headerright'>
                <div className='docprologo' onClick={() => window.open("https://www.docpro.com.br")} />
              </div>
            </div>
          </>
        }

        <div>
          <div className='foldercurrent' data-content={Folder === null ? 'Carregando...' : Folder.Path} />
        </div>
      </div>
    </>
  );
};

Header.displayName = 'Header';