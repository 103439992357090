import * as React from 'react';
import FoldersControls from '../FoldersControls';
import FoldersTreeView from '../FoldersTreeView';
import LibraryDetails from '../LibraryDetails';
import SharedSocialButtons from '../SharedSocialButtons';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { folderIcon, infoCircleIcon, zoomInIcon, downloadIcon } from '@progress/kendo-svg-icons';
import { Header } from './Header';
import { ExportPdfControl } from '../ExportPdf';
import { useControlContext } from '../../context/ControlContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { getFirstPixelColor } from '../../services/shared';
import { isMobile } from 'react-device-detect';

const DocReaderDrawerRouterContainer = (props: any) => {
  const children = props.children;
  const { library } = props;
  const [ isFoldersVisible, setIsFoldersVisible ] = React.useState(false);
  const [ isPdfVisible, setIsPdfVisible ] = React.useState(false);
  const [ isLibraryDetailsVisible, setIsLibraryDetailsVisible ] = React.useState(false);
  const [ isSearch, setIsSearch ] = React.useState(false);
  const { commandControlContext, setCommandControlContext } = useControlContext();
  const { Library } = useLibraryContext();
  const [ headerMain, setHeaderMain ] = React.useState<React.CSSProperties>({});

  const items = [
    {
      text: 'Pastas',
      svgIcon: folderIcon,
      selected: true,
    },
    {
      text: 'Zoom',
      svgIcon: zoomInIcon,
    },
    {
      text: 'Download Pdf',
      svgIcon: downloadIcon,
    },
    {
      text: 'Info',
      svgIcon: infoCircleIcon
    },
  ];

  const mobileItems = [
    {
      text: 'Pastas',
      svgIcon: folderIcon,
      selected: true,
    },
  ];

  const [expanded] = React.useState(false);

  const onSelect = (e: any) => {
    if (e.itemTarget.props.text === 'Pastas') {
      setIsFoldersVisible(!isFoldersVisible);
      setIsPdfVisible(false);
      setIsLibraryDetailsVisible(false);
    }
    else if (e.itemTarget.props.text === 'Zoom') {
      setCommandControlContext({ isZoomVisible: !commandControlContext?.isZoomVisible });
    }
    else if (e.itemTarget.props.text === 'Download Pdf') {
      setIsFoldersVisible(false);
      setIsPdfVisible(!isPdfVisible);
      setIsLibraryDetailsVisible(false);
    }
    else if (e.itemTarget.props.text === 'Info') {
      setIsFoldersVisible(false);
      setIsPdfVisible(false);
      setIsLibraryDetailsVisible(!isLibraryDetailsVisible);
    }
  };

  if (Library &&
    Library?.headerImg?.contentType !== undefined &&
    Library?.headerImg?.fileContents !== undefined) {
    const image = new Image();
    image.onload = () => {
      getFirstPixelColor(image).then(color => {
        const cor: string = color.toString()
        setHeaderMain({
          backgroundColor: cor,
          backgroundImage: 'unset'
        });
      }).catch(error => {
        console.error('Failed to get first pixel color:', error);
      });
    };
    image.src = `data:${Library.headerImg.contentType};base64,${Library.headerImg.fileContents}`;
  } 

  return (
    <>
      <FoldersControls library={library} />
      
      {isMobile ? 
        <div>
          <Header isSearch={isSearch} setIsSearch={setIsSearch} />

          <div className='documentedgediv'>
            <div className='documentedgecontentdiv'/>
          </div>
          
          <Drawer expanded={false} className={'drawer-mobile folderIcon-mobile'} position={'start'} mini={true}
                  items={mobileItems.map((item) => ({...item,selected: false}))} onSelect={onSelect}>
            <DrawerContent>
              <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible}/>
              {children}
            </DrawerContent>
          </Drawer>
        </div>
      : 
        <div>
          <Header isSearch={isSearch} setIsSearch={setIsSearch} />

          <div className='documentedgediv'>
            <div className='documentedgecontentdiv' />
          </div>

          <Drawer expanded={expanded} position={'start'} mode={'push'} mini={true} items={items.map((item) => ({...item,selected: false}))} onSelect={onSelect}>          
            <DrawerContent>
              <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible} />

              {isLibraryDetailsVisible ? <LibraryDetails isVisible={isLibraryDetailsVisible} setIsVisible={setIsLibraryDetailsVisible} /> : null}

              <ExportPdfControl library={library} isVisible={isPdfVisible} setIsVisible={setIsPdfVisible} />

              {children}
            </DrawerContent>
          </Drawer>

          <SharedSocialButtons />
        </div>
      }
    </>
  );
}

export default DocReaderDrawerRouterContainer;